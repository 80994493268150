<template>
  <div>
    <div class="reward-details" v-if="reward !== null">
      <div class="reward-details__breadcrumbs" v-if="!$root.isMobile">
        <span @click.stop="onMarket">Маркет</span> / Скидки
      </div>
      <div class="reward-details__image-info">
        <div class="reward-details__image">
          <img :src="reward.images ? ($root.isMobile ? reward.images.mobile : reward.images.desktop) : ''" />
          <div class="reward-details__back" v-if="$root.isMobile && !isMobileApp">
            <Button
              variant="secondary"
              :size="$root.isMobile ? 'sm' : 'lg'"
              shape="circle"
              iconLeft="left"
              mobile
              @click="onBack"
            ></Button>
          </div>
        </div>
        <div class="divider reward-details__divider" v-if="$root.isMobile"></div>
        <div class="reward-details__info">
          <div>
            <div class="info__heading" v-html="reward.name || ''"></div>
            <div class="info__subheading" v-html="reward.subTitle || ''"></div>
          </div>
          <div class="info__count" v-if="!$root.isMobile && isSmallQuantity() && reward.promocodeQty > 0">Осталось {{ this.reward.promocodeQty || '-' }} шт.</div>
          <div class="info__price-count" v-if="$root.isMobile">
            <div class="info__price">
              <img :src="coin" width="32" height="32" /><span v-html="reward.priceInPoints || ''"></span>
            </div>
            <div class="info__count" v-if="isSmallQuantity() && reward.promocodeQty > 0">Осталось {{ this.reward.promocodeQty || '-' }} шт.</div>
          </div>
          <div class="info__button">
            <div class="info__price" :class="{ 'price--grayscale': isOutOfStock }" v-if="!$root.isMobile">
              <img :src="coin" width="32" height="32" /><span v-html="reward.priceInPoints || ''"></span>
            </div>
            <Button
              v-if="!$root.isMobile || ($root.isMobile && backendMessage === '')"
              variant="primary"
              size="lg"
              :text="isOutOfStock || isRewardDisabled ? 'Нет в наличии' : 'Купить за баллы'"
              :wide="$root.isMobile"
              :disabled="loading || isOutOfStock || isRewardDisabled || backendMessage !== ''"
              :loading="loading"
              mobile
              @click="onShowConfirm"
            ></Button>
            <Alert
              v-if="backendMessage"
              :text="backendMessage"
              state="warning"
              no-action
            >
            </Alert>
          </div>
        </div>
      </div>
      <div v-if="reward.raffleOn && reward.type === 'Prize'" class="reward-details__raffle">
        <div class="reward-details__raffle-item">
          <Icon name="calendar" size="l" />
          <span>Розыгрыш {{ formatDate(reward.raffleOn) }}</span>
        </div>
        <!-- <div class="reward-details__raffle-item">
          <Icon name="user-groups" size="l" />
          <span>147 участников</span>
        </div> -->
        <!-- <div class="reward-details__raffle-item">
          <Icon name="ticket" size="l" />
          <span>У вас 0 билетов</span>
        </div> -->
      </div>

      <div class="divider reward-details__divider" v-if="$root.isMobile"></div>
      <div class="reward-details__description">
        <Tabs
          v-if="!$root.isMobile"
          class="description__tabs"
          :items="tabs"
          :activeItem="selectedTab"
          @changed="onChangeTab"
        />
        <div class="divider" v-if="!$root.isMobile"></div>
        <div class="description__title" v-if="$root.isMobile">Описание</div>
        <div class="description__title-partner" v-if="isVisible(0)">
          <div :class="{ 'w-100': !hasPartner }">
            <div class="description__text" v-html="reward.description || ''" ref="rewardDescriptionBlock"></div>
            <div class="description__partner-link" v-if="reward.descriptionUrl">
              <Link v-if="!isMobileApp" variant="primary" size="md" icon-left="externa-link" tag="a" :href="reward.descriptionUrl || ''" target="_blank">Подробнее на сайте партнёра</Link>
              <Link v-if="isMobileApp" variant="primary" size="md" icon-left="externa-link" @click.stop=onRewardExternalLink(reward.descriptionUrl)>Подробнее на сайте партнёра</Link>
            </div>
          </div>
          <div class="description__partner" v-if="hasPartner">
            <div class="description__partner-logo" v-if="reward.partner.logos"><img :src="$root.isMobile ? reward.partner.logos.mobile : reward.partner.logos.desktop" /></div>
            <div class="description__partner-name" v-else v-html="reward.partner.name || ''"></div>
            <Link v-if="!isMobileApp" variant="secondary" size="sm" icon-left="externa-link" tag="a" :href="reward.partner.url || ''" target="_blank">Информация о партнёре</Link>
            <Link v-if="isMobileApp" variant="secondary" size="sm" icon-left="externa-link" @click.stop=onRewardExternalLink(reward.partner.url)>Информация о партнёре</Link>
          </div>
        </div>
        <div class="description__title" v-if="$root.isMobile">Как оплатить и получить </div>
        <div class="description__pay-get" v-if="isVisible(1)">
          <div class="description__text" v-html="reward.howToGet || ''" ref="rewardHowToBlock"></div>
        </div>
      </div>
    </div>

    <Modal
      v-if="reward !== null"
      name="reward-confirm"
      :img="coin"
      title="С вашего счета будет списано"
      :subtitle="`${reward.priceInPoints || ''} баллов`"
      :active="showConfirm"
      :class="{ 'for-mobile-app show-modal-close': isMobileApp }"
      :noTransition="true"
      @close.prevent="onConfirmClose"
    >
      <div class="buttons-group">
        <div class="buttons-group__item">
          <Button
            variant="primary"
            size="lg"
            text="Подтвердить покупку"
            @click="onBuy"
            :loading="loading"
            :disabled="loading"
            wide
            mobile
          ></Button>
        </div>
        <div class="buttons-group__item buttons-group__item_first">
          <Button
            variant="secondary"
            size="lg"
            text="Отменить"
            @click="onConfirmClose"
            :loading="loading"
            :disabled="loading"
            wide
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

    <Modal
      v-if="reward !== null"
      name="reward-bought"
      state="success"
      title="Спасибо за покупку!"
      subtitle="Ваш промокод для получения товара:"
      :active="showBought"
      :class="{ 'for-mobile-app show-modal-close': isMobileApp }"
      :noTransition="true"
      @close.prevent="onBoughtClose"
      class="bought"
    >
      <div class="reward-bought__promocode" v-html="code"></div>
      <div class="reward-bought__message text-left" v-html="steps" ref="rewardBoughtSteps"></div>
      <div class="buttons-group">
        <div v-if="showGoToPartnerButton  && !showWelcomeButtons" class="buttons-group__item">
          <Button
            v-if="!isMobileApp"
            variant="primary"
            size="lg"
            text="На сайт партнёра"
            iconRight="externa-link"
            tag="a"
            target="_blank"
            :href="reward.partner.url"
            wide
            mobile
          ></Button>
          <Button
            v-if="isMobileApp"
            variant="primary"
            size="lg"
            text="На сайт партнёра"
            iconRight="externa-link"
            @click="onRewardExternalLink(reward.partner.url)"
            wide
            mobile
          ></Button>
        </div>
        <div v-if="showWelcomeButtons" class="buttons-group__item">
          <Button
            variant="primary"
            size="lg"
            text="Продолжить покупки"
            @click="onGoMarket"
            wide
            mobile
          ></Button>
        </div>
        <div v-if="!showWelcomeButtons" class="buttons-group__item buttons-group__item_first">
          <Button
            variant="secondary"
            size="lg"
            text="История покупок"
            @click="onGoHistory"
            wide
            mobile
          ></Button>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Alert from '@rr-component-library/alert/src/main';
import dayjs from 'dayjs';
import Coin from '@/assets/img/coin.svg';

const { v4: uuidv4 } = require('uuid');

export default {
  name: 'RewardDetails',
  components: {
    Alert,
  },
  data() {
    return {
      reward: null,
      promotionId: null,
      coin: Coin,
      tabs: [
        { title: 'Описание', enabled: true },
        { title: 'Как оплатить и получить', enabled: true },
      ],
      selectedTab: 0,
      showConfirm: false,
      showBought: false,
      code: '',
      steps: '',
      errorMessages: {
        not_enough_points_to_buy: 'Для покупки не хватает <b>{0} балла</b>. <br><a href="{{articleAboutTasks}}" target="_blank">Как заработать?</a>',
        purchase_limit_on_participant_reached: 'Извините, данное поощрение можно приобрести только {0} раз(а). Ранее вы его уже приобрели. Можете выбрать другие поощрения в <a href="/market">Маркете</a>.',
      },
      showError: false,
      backendMessage: '',
      loading: false,
      isPromocode: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['themeDone', 'resources', 'options']),
    ...mapGetters('wallet', ['balance']),
    ...mapGetters('market', ['promotion', 'promocode', 'purchaseId', 'responseStatus', 'errorCode']),
    ...mapGetters('user', ['isMobileApp']),
    hasPartner() {
      if (!this.reward) return false;
      if (this.reward.partner) return true;
      return false;
    },
    rewardDescription() {
      if (!this.reward) return '';
      if (this.reward.description) return this.reward.description;
      return '';
    },
    isOutOfStock() {
      if (!this.reward) return false;
      if (this.reward.promocodeQty === 0) return true;
      return false;
    },
    isRewardDisabled() {
      if (!this.reward) return false;
      if (this.reward.disabledOn) return true;
      return false;
    },
    showGoToPartnerButton() {
      return this.reward ? this.reward.type === 'Discount' || this.reward.type === 'Promocode' : false;
    },
    showWelcomeButtons() {
      return this.options ? this.options.showWelcomeButtons === true : false;
    },
    articleAboutTasks() {
      return this.resources ? this.resources.articleAboutTasks || '#' : '#';
    },
  },
  watch: {
    themeDone(val) {
      if (val) {
        this.errorMessages.not_enough_points_to_buy = this.replaceVariables(this.errorMessages.not_enough_points_to_buy);
      }
    },
    rewardDescription(val) {
      if (val) {
        setTimeout(() => { this.setLinkListeners(); }, 100);
      }
    },
  },
  methods: {
    ...mapActions('wallet', ['getBalance']),
    ...mapActions('market', ['getPromotion', 'getPromocodes', 'buyPromotion']),
    onGoMarket() {
      this.onBoughtClose();
      if (this.isMobileApp) {
        setTimeout(() => { this.$root.$goPage('Market', '/market'); }, 200);
        return;
      }
      setTimeout(() => { this.$router.push({ name: 'Market' }).catch(() => {}); }, 200);
    },
    onRewardExternalLink(url) {
      if (!url) return;
      this.$root.$openInExternal(url, `${window.location.pathname}?id=${this.$route.query.id}`);
    },
    onBack() {
      const from = this.$route.query.from || '';
      if (from) {
        this.$router.push({ name: from }).catch(() => {});
        return;
      }
      this.$router.push({ name: 'Market' }).catch(() => {});
    },
    onMarket() {
      this.$router.push({ name: 'Market' }).catch(() => {});
    },
    onShowConfirm() {
      if (this.loading) return;
      window.location.hash = '#!showConfirm=true';
      this.showConfirm = true;
    },
    onConfirmClose() {
      window.location.hash = '';
      this.showConfirm = false;
    },
    pollPromocodes(attempts) {
      if (attempts === 0) {
        return;
      }
      if (this.$route.name !== 'RewardDetails') {
        return;
      }
      this.getPromocodes(this.purchaseId)
        .then(() => {
          if (this.responseStatus !== 200) {
            setTimeout(() => {
              this.pollPromocodes(attempts - 1);
            }, 1000);
          } else {
            this.code = this.promocode;
          }
          this.getBalance();
          this.getPromotion(this.promotionId)
            .then(() => {
              this.reward = this.promotion;
              setTimeout(() => {
                if (this.$route.name !== 'RewardDetails') {
                  return;
                }
                this.onShowBought();
                this.loading = false;
              }, 500);
            });
        });
    },
    onBuy() {
      if (this.promotionId && !this.loading) {
        const purchaseId = uuidv4();
        this.backendMessage = '';
        this.onConfirmClose();
        this.loading = true;
        this.getPromotion(this.promotionId)
          .then(() => {
            if (this.responseStatus === 200) {
              this.reward = this.promotion;
              if (this.isOutOfStock) {
                this.loading = false;
                return;
              }
              this.buyPromotion({ promotionId: this.promotionId, purchaseId })
                .then(() => {
                  if (this.responseStatus === 200) {
                    this.pollPromocodes(20);
                  } else if (this.responseStatus === 409) {
                    this.setErrorMessage(this.errorCode);
                    this.loading = false;
                  } else {
                    this.backendMessage = `Произошла ошибка: ${this.responseStatus}`;
                    this.loading = false;
                  }
                });
              return;
            }
            this.loading = false;
          });
      }
    },
    onShowBought() {
      window.location.hash = '#!showBought=true';
      this.showBought = true;
    },
    onBoughtClose() {
      window.location.hash = '';
      this.showBought = false;
    },
    onGoHistory() {
      this.onBoughtClose();
      if (this.isMobileApp) {
        setTimeout(() => { this.$root.$goPage('SettingsHistory', '/my-settings/purchase-history'); }, 250);
        return;
      }
      setTimeout(() => { this.$router.push({ name: 'SettingsHistory' }).catch(() => {}); }, 250);
    },
    onChangeTab(index) {
      this.selectedTab = index;
    },
    isVisible(selectedTab) {
      if (this.$root.isMobile) return true;
      if (selectedTab === this.selectedTab) return true;
      return false;
    },
    isSmallQuantity() {
      if (!this.reward) return false;
      return this.reward.promocodeQty <= 5;
    },
    setErrorMessage(errorCode) {
      if (!this.reward) return;
      if (errorCode === 'not_enough_points_to_buy') {
        const coins = Math.abs(this.balance.amount - this.reward.priceInPoints);
        const message = this.errorMessages.not_enough_points_to_buy;
        if (!message) return;
        this.backendMessage = message.replace('{0}', coins);
        return;
      }
      if (errorCode === 'purchase_limit_on_participant_reached') {
        const message = this.errorMessages.purchase_limit_on_participant_reached;
        if (!message) return;
        const count = this.reward ? this.reward.limitOnParticipant || 1 : 1;
        this.backendMessage = message.replace('{0}', count);
        return;
      }
      this.backendMessage = 'Произошла ошибка';
    },
    setSteps() {
      if (!this.reward) return;
      this.steps = this.reward.howToGet || '';
    },
    replaceVariables(text) {
      if (!text) return '';
      const vars = [
        { id: 'articleAboutTasks', value: this.articleAboutTasks },
      ];
      let result = text;
      for (let i = 0; i < vars.length; i += 1) {
        result = result.replaceAll(`{{${vars[i].id}}}`, vars[i].value);
      }
      return result;
    },
    formatDate(datePrize) {
      const date = dayjs(datePrize).format('DD.MM.YYYY');
      return date;
    },
    setLinkListeners() {
      if (!this.isMobileApp) return;

      const { rewardDescriptionBlock, rewardHowToBlock, rewardBoughtSteps } = this.$refs;
      const blocks = [rewardDescriptionBlock, rewardHowToBlock, rewardBoughtSteps];

      for (let i = 0; i < blocks.length; i += 1) {
        if (!blocks[i]) continue;
        const links = blocks[i].querySelectorAll('a');
        this.setListeners(links);
      }
    },
    setListeners(links) {
      for (let i = 0; i < links.length; i += 1) {
        const href = links[i].getAttribute('href');
        links[i].addEventListener('click', () => {
          this.$root.$openInExternal(href, `${window.location.pathname}?id=${this.$route.query.id}`);
        });
        links[i].setAttribute('href', '#');
        links[i].setAttribute('target', '');
      }
    },
  },
  mounted() {
    if (!this.$route.query.id) {
      this.$router.push({ name: 'Market' }).catch(() => {});
      return;
    }
    if (this.themeDone) {
      this.errorMessages.not_enough_points_to_buy = this.replaceVariables(this.errorMessages.not_enough_points_to_buy);
    }
    this.getPromotion(this.$route.query.id)
      .then(() => {
        if (this.responseStatus === 200) {
          this.reward = this.promotion;
          this.promotionId = this.$route.query.id;
          this.setSteps();
        } else {
          this.reward = null;
          this.promotionId = null;
          this.$router.push({ name: 'Market' }).catch(() => {});
        }
      });
  },
};
</script>

<style lang="scss" scoped>
  @import './RewardDetails';
</style>
